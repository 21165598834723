import { defineStore } from 'pinia';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const useApplicationInsights = defineStore('applicationInsights', {
  state: () => ({
    isInitialized: false as boolean,
    appInsights : {} as ApplicationInsights,
  }),
  actions: {
    initialize() {
      const runtimeConfig = useRuntimeConfig();
      if (runtimeConfig.public.applicationInsightsConnectionString) {
        this.appInsights = new ApplicationInsights({ config: {
          connectionString: runtimeConfig.public.applicationInsightsConnectionString,
          cookieCfg: {
            enabled: false,
          },
        } });
        this.appInsights.loadAppInsights();
        window.appInsights = this.appInsights;
        this.isInitialized = true;
      }
    },
    async trackPageView(name: string, uri : string) {
      if (this.isInitialized) {
        this.appInsights.trackPageView({ name, uri });
      }
    },
    async trackTrace(message: string) {
      if (this.isInitialized) {
        this.appInsights.trackTrace({ message: message });
      }
    },
  },
});
