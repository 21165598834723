import { type RouterConfig } from '@nuxt/schema';
import { useScrollStore } from '@/store/scrollStore';

// https://router.vuejs.org/guide/advanced/scroll-behavior.html
export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    const scrollStore = useScrollStore();
    if (savedPosition) {
      return savedPosition;
    }

    if (to.query.page !== from.query.page) {
      return false;
    }

    if (scrollStore.disableScroll) {
      return false;
    }

    window.scrollTo(0, 0);
  }
};