import { defineStore } from 'pinia';
import gtmEvents from '~/constants/gtm-events';
import { useGlobalContentStore } from '~/store/globalContent';

export const useErrorStore = defineStore('error', {
  state: () => ({
    errorMessage: '' as string,
    errorMessageBuyButton: '' as string,
    hideCustomerService: false as boolean | undefined,
    activeProduct: '' as string,
  }),
  actions: {
    displayError(payload?: { message?: string; status?: number, hideCustomerService?: boolean }) {
      const globalContentStore = useGlobalContentStore();
      const errorMessage = payload && payload.message || globalContentStore.sharedResources.genericError;
      this.errorMessage = errorMessage;
      this.hideCustomerService = payload && payload.hideCustomerService;
      window.dataLayer?.push({
        event: gtmEvents.Error,
        errorStatus: payload && payload.status,
        errorMessage,
      });
    },
    displayBuyButtonError(payload?: { message?: string; activeProduct?: string, hideCustomerService?: boolean }) {
      const globalContentStore = useGlobalContentStore();
      const errorMessageBuyButton = payload && payload.message || globalContentStore.sharedResources.genericError;
      this.errorMessageBuyButton = errorMessageBuyButton;
      this.hideCustomerService = payload && payload.hideCustomerService;
      this.activeProduct = payload && payload.activeProduct || '';
      window.dataLayer?.push({
        event: gtmEvents.Error,
        errorMessageBuyButton,
      });
    },
    clearError() {
      this.errorMessage = '';
      this.hideCustomerService = false;
    },
    clearBuyButtonError() {
      this.errorMessageBuyButton = '';
      this.hideCustomerService = false;
    },
  },
});
