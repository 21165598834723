// This plugin sets up the communication between on-page-edit
// and our application. When content has changed, we need to
// update our state.

import { usePageContentStore } from '~/store/pageContent';

export default defineNuxtPlugin(nuxtApp => {
  const pageStore = usePageContentStore();
  nuxtApp.hook('page:finish', () => {
    if (process.server) {
      return;
    }

    window.addEventListener('message', async(event) => {
      if (event.data.id === 'contentSaved') {
        const previewUrl = new URL(event.data.data.previewUrl);
        await pageStore.loadContent(previewUrl.pathname + previewUrl.search + previewUrl.hash);
      }
    }, false);
  });
});
