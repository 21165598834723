import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArticleListingPage, LazyArticlePage, LazyBlockPreview, LazyBrandListingPage, LazyBrandPage, LazyCheckoutConfirmationPage, LazyCheckoutPage, LazyContactUsPage, LazyEmailAddressVerificationPage, LazyLoginPage, LazyMyAccountPage, LazyProductListingPage, LazyProductPage, LazyRegistrationPage, LazyResetPasswordPage, LazySearchResultsPage, LazyStandardBlockPage, LazyStartPage, LazyStorePage, LazyStoreReportPage, LazySubscriptionProductListingPage, LazyAccordionBlock, LazyBrandListingBlock, LazyBrandListingBlockItem, LazyCardBlock, LazyCookieDeclarationBlock, LazyCoverBlock, LazyCoverMultipleBlock, LazyCustomerServiceCardItem, LazyCustomerServiceCardsBlock, LazyFaqBlock, LazyFavouritesBlock, LazyFormContainerBlock, LazyHistoryTimelineBlock, LazyIconCardsBlock, LazyImageCardsBlock, LazyImageSliderBlock, LazyIngridTrackingWidgetBlock, LazyLinkButtonListingBlock, LazyLinkCardListingBlock, LazyNicotineWarningBlock, LazyPageHeroBlock, LazyPersonalizedProductListingBlock, LazyProductListingBlock, LazyProductPromoBlock, LazyQuoteBlock, LazySimpleProductListingBlock, LazyStatisticItem, LazyStatisticsBlock, LazyStoreListingBlock, LazyStoreListingBlockItem, LazyTextImageVideoBlock, LazyTypographyBlock, LazyWysiwygBlock, LazyWysiwygWrapper, LazyImageFile } from '#components'
const lazyGlobalComponents = [
  ["ArticleListingPage", LazyArticleListingPage],
["ArticlePage", LazyArticlePage],
["BlockPreview", LazyBlockPreview],
["BrandListingPage", LazyBrandListingPage],
["BrandPage", LazyBrandPage],
["CheckoutConfirmationPage", LazyCheckoutConfirmationPage],
["CheckoutPage", LazyCheckoutPage],
["ContactUsPage", LazyContactUsPage],
["EmailAddressVerificationPage", LazyEmailAddressVerificationPage],
["LoginPage", LazyLoginPage],
["MyAccountPage", LazyMyAccountPage],
["ProductListingPage", LazyProductListingPage],
["ProductPage", LazyProductPage],
["RegistrationPage", LazyRegistrationPage],
["ResetPasswordPage", LazyResetPasswordPage],
["SearchResultsPage", LazySearchResultsPage],
["StandardBlockPage", LazyStandardBlockPage],
["StartPage", LazyStartPage],
["StorePage", LazyStorePage],
["StoreReportPage", LazyStoreReportPage],
["SubscriptionProductListingPage", LazySubscriptionProductListingPage],
["AccordionBlock", LazyAccordionBlock],
["BrandListingBlock", LazyBrandListingBlock],
["BrandListingBlockItem", LazyBrandListingBlockItem],
["CardBlock", LazyCardBlock],
["CookieDeclarationBlock", LazyCookieDeclarationBlock],
["CoverBlock", LazyCoverBlock],
["CoverMultipleBlock", LazyCoverMultipleBlock],
["CustomerServiceCardItem", LazyCustomerServiceCardItem],
["CustomerServiceCardsBlock", LazyCustomerServiceCardsBlock],
["FaqBlock", LazyFaqBlock],
["FavouritesBlock", LazyFavouritesBlock],
["FormContainerBlock", LazyFormContainerBlock],
["HistoryTimelineBlock", LazyHistoryTimelineBlock],
["IconCardsBlock", LazyIconCardsBlock],
["ImageCardsBlock", LazyImageCardsBlock],
["ImageSliderBlock", LazyImageSliderBlock],
["IngridTrackingWidgetBlock", LazyIngridTrackingWidgetBlock],
["LinkButtonListingBlock", LazyLinkButtonListingBlock],
["LinkCardListingBlock", LazyLinkCardListingBlock],
["NicotineWarningBlock", LazyNicotineWarningBlock],
["PageHeroBlock", LazyPageHeroBlock],
["PersonalizedProductListingBlock", LazyPersonalizedProductListingBlock],
["ProductListingBlock", LazyProductListingBlock],
["ProductPromoBlock", LazyProductPromoBlock],
["QuoteBlock", LazyQuoteBlock],
["SimpleProductListingBlock", LazySimpleProductListingBlock],
["StatisticItem", LazyStatisticItem],
["StatisticsBlock", LazyStatisticsBlock],
["StoreListingBlock", LazyStoreListingBlock],
["StoreListingBlockItem", LazyStoreListingBlockItem],
["TextImageVideoBlock", LazyTextImageVideoBlock],
["TypographyBlock", LazyTypographyBlock],
["WysiwygBlock", LazyWysiwygBlock],
["WysiwygWrapper", LazyWysiwygWrapper],
["ImageFile", LazyImageFile],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
