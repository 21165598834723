//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface IBrandItem
{
	id: string;
	name?: string;
	urlSegment?: string;
	description?: string;
	listingName?: string;
	url?: string;
	imageUrl?: string;
	code?: string;
	countryCode?: string;
	language: string;
	sortOrder?: number;
	brandPageId: number;
}
export interface IProduct
{
	id: string;
	partNo: string;
	name?: string;
	metaTitle?: string;
	description?: string;
	metaDescription?: string;
	variants: IVariant[];
	flags: IFlag[];
	tags: ITag[];
	ingredients?: string;
	countryCode?: string;
	language?: string;
	uniqueUrlName?: string;
	brand: IBrand;
	categoryCode?: string;
	status: ProductStatus;
	imageUrl?: string;
	files: IFile[];
	facts: IParametric[];
	properties: IParametric[];
	flavorProfile: IParametric[];
	url?: string;
	productRelations: any[];
	productListingUrlSegment?: string;
	subscribableVariants: IVariant[];
	popularityRank?: number;
	isNew: boolean;
	productType: ProductType;
	shortDescription?: string;
	isRunner: boolean;
	nextDeliveryDate?: any;
	recommendationPromotionWeight?: number;
	category: IProductCategory;
	isRecommendedSalesQuantityFixed?: boolean;
	recommendedQty?: number;
	productId: number;
	isExpiring: boolean;
}
export enum ProductType {
	Standard = 0,
	Bundle = 1,
	Freight = 2
}
export interface IProductCategory
{
	code?: string;
	name?: string;
	productListingUrl?: string;
	urlSegment?: string;
}
export interface IFile
{
	type?: string;
	fileCode?: string;
	mimeType?: string;
	url?: string;
}
export interface IParametric
{
	code?: string;
	name?: string;
	value?: string;
	urlSegment?: string;
	values: IParametricValue[];
}
export interface IParametricValue
{
	value?: string;
	urlSegment?: string;
	code?: string;
}
export interface IVariant
{
	partNo?: string;
	id: number;
	name?: string;
	status: ProductStatus;
	price: IPrice;
	packageSize: number;
	inStock: boolean;
	availability: IAvailability;
	grossWeightInGrams?: number;
	nextDeliveryDate?: any;
	eanCode?: string;
	isRecommendedSalesQuantityFixed?: boolean;
	recommendedQty?: number;
	isPreferredVariant: boolean;
}
export interface IPrice
{
	value?: number;
	priceListId?: number;
	valuePerCan?: number;
	currencyCode?: string;
}
export interface IAvailability
{
	online: boolean;
	inStore: boolean;
}
export enum ProductStatus {
	Active = 0,
	Coming = 1,
	Expiring = 2,
	Hidden = 3,
	Inactive = 4
}
export enum VariantStatus {
	Active = 1,
	Coming = 2,
	Expiring = 3,
	Hidden = 4,
	Inactive = 5,
	NotActivated = 6
}
export interface IFlag
{
	name?: string;
	urlSegment?: string;
}
export interface IBrand
{
	name?: string;
	code?: string;
	urlSegment?: string;
}
export interface ITag
{
	name: string;
	urlSegment: string;
	type: TagType;
	sortOrder?: number;
	isVirtualPage: boolean;
}
export enum TagType {
	Brand = "Brand",
	Type = "Type",
	Format = "Format",
	Strength = "Strength",
	Flavor = "Flavor",
	Flag = "Flag",
	Category = "Category"
}
export interface IAvailableSlotsModel
{
	id?: any;
	storeId: number;
	productName?: string;
	productNumber: number;
	numberOfCans: number;
	week: number;
	day: number;
	year: number;
	cutOffDate: any;
	published: boolean;
}
export interface IBookingModel
{
	quantity: number;
	productName?: string;
	productNumber: number;
	numberOfCans: number;
	slotId: any;
	storeId: number;
	id?: any;
	status: number;
	statusString?: string;
	week: number;
	day: number;
	year: number;
	date: any;
	customerName?: string;
	customerEmail?: string;
	customerPhone?: string;
	readyAt?: any;
	deliveredAt?: any;
	cancelledAt?: any;
}
export enum BookingStatus {
	Recieved = 1,
	ReadyForPickup = 2,
	Cancelled = 4
}
export interface IBrandItem
{
	name?: string;
	url?: string;
	imageUrl?: string;
}
export interface IBrandListingBlockData
{
	brands: IBrandItem[];
}
export interface ICart
{
	items: ICartItem[];
	total: number;
	totalDiscount: number;
	shipping: number;
	checkoutUrl?: string;
	cartType: CartType;
	appliedPromotions: IPromotionItem[];
	subscriptionInterval?: SubscriptionInterval;
	abandonedCartUrl?: string;
	id?: number;
	vat: number;
}
export interface ICartItem
{
	partNo: string;
	quantity: number;
	priceListId: number;
	price?: number;
	originalPrice?: number;
	imageUrl?: string;
	availability: IAvailability;
	name?: string;
	productName?: string;
	packageSize: number;
	lineNo?: number;
	unitPrice?: number;
	category?: string;
	brand?: string;
	url?: string;
	id?: string;
	variantId?: number;
}
export enum CartType {
	Standard = "Standard",
	Subscription = "Subscription"
}
export interface ICheckoutConfirmation
{
	snippet?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	cart: ICart;
	isNewCustomer: boolean;
	shippingMethod?: string;
	shippingSessionError: boolean;
}
export interface ICommandResult<T> extends IICommandResultBase
{
	value?: T;
}
export interface IICommandResultBase
{
	success: boolean;
	errorMessage?: string;
	validationErrors: IValidationError[];
	userNotVerified: boolean;
	redirect?: string;
}
export interface ICompanySettings
{
	name: string;
	businessRegistrationNumber: string;
	vatRegistrationNumber: string;
	address: string;
	phone: string;
	website: string;
}
export interface IContentItem
{
	url: string;
	name: string;
}
export enum DeliveryType {
	Standard = "Standard",
	InStore = "InStore"
}
export interface IIngridSnippetResult
{
	snippet?: string;
	canDeliverToStore: boolean;
}
export interface ILoginStatus
{
	isLoggedIn: boolean;
	isAdmin: boolean;
	isIdentifiedContact: boolean;
	isImpersonating: boolean;
	canImpersonate: boolean;
	contactId?: string;
}
export interface IMarket
{
	country: string;
	language: string;
	url?: string;
	currencyCode: string;
	countryCode: string;
	isCurrentMarket: boolean;
}
export interface INotice
{
	backgroundColor?: string;
	iconColor?: string;
	icon?: string;
	text?: string;
	useCountdown?: boolean;
	countdownDate?: string;
	visibleFor?: string;
	id?: string;
}
export interface IOrder
{
	storeName: string;
	orderNumber: string;
	orderDate?: any;
	statusDisplayName: string;
	estimatedDeliveryDate?: any;
	statusValue: string;
	deliveryMethod?: string;
	orderTotal?: number;
	shipping?: number;
	items: IOrderItem[];
	customerName: string;
	addressLine1: string;
	zipCode: string;
	city: string;
	vat?: number;
	trackingIdentifier?: string;
	orderType: OrderType;
	id?: number;
}
export interface IOrderItem
{
	name: string;
	quantity?: number;
	partNo: string;
	pricelistId?: number;
	price?: number;
	unitPrice?: number;
	productStatus?: ProductStatus;
	imageUrl?: string;
	productUrl?: string;
	inStock: boolean;
	isRunner: boolean;
	category?: string;
	brand?: string;
	id?: string;
	packageSize: number;
}
export interface IOrderResult
{
	orders: IOrder[];
	totalCount: number;
}
export enum OrderType {
	Online = "Online",
	Store = "Store"
}
export interface IPagedList<T>
{
	items: T[];
	pageCount: number;
	totalItemCount: number;
	pageNumber: number;
	pageSize: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	isFirstPage: boolean;
	isLastPage: boolean;
}
export interface IPreference
{
	name: string;
	displayName: string;
	isSelected: boolean;
	imageUrl?: string;
}
export interface IProductFact
{
	name?: string;
	url?: string;
	values: IProductFactValue[];
}
export interface IProductFactValue
{
	name?: string;
	url?: string;
	linksToVirtualPage: boolean;
}
export interface IProductPageData
{
	product: IProduct;
	relatedProducts: IProductRelationModel[];
	sameBrandProducts: IProduct[];
	facts: IProductFact[];
}
export interface IProductRelationModel
{
	type: string;
	product: IProduct;
}
export interface IPromotionItem
{
	name: string;
	hasCode: boolean;
	appliedAmount?: number;
	code?: string;
	activated: boolean;
}
export interface IQuickNavigatorStatus
{
	showQuickNavigator: boolean;
	contentId?: number;
}
export interface IRedirectsResult
{
	shouldRedirect: boolean;
	newUrl: string;
	permanent: boolean;
}
export interface ISearchResults
{
	totalHits: number;
	totalPages: number;
	brands: IContentItem[];
	content: IContentItem[];
	products: IProduct[];
	searchResultUrl?: string;
	tags: ITag[];
}
export interface ISimpleLinkItem
{
	linkUrl?: string;
	linkText?: string;
	underline?: boolean;
	icon?: string;
	target?: string;
	pageTypeName?: string;
}
export interface ISiteSettingsResult
{
	siteSettings: ISiteSettings;
	contentType?: string;
	antiForgeryToken?: string;
}
export interface ISnusPreferences
{
	brands: IPreference[];
	types: IPreference[];
	formats: IPreference[];
	strengths: IPreference[];
	flavors: IPreference[];
	misc: IPreference[];
}
export enum SortMode {
	Popularity = 0,
	News = 1,
	Price = 2,
	PriceDesc = 3
}
export interface IStoreItem
{
	name?: string;
	url?: string;
	imageUrl?: string;
	imageAltText?: string;
	pageTitle?: string;
	city?: string;
	address?: string;
}
export interface IStoreListingBlockData
{
	stores: IStoreItem[];
}
export enum SubscriptionInterval {
	EveryWeek = "EveryWeek",
	EveryOtherWeek = "EveryOtherWeek",
	EveryThreeWeeks = "EveryThreeWeeks",
	EveryFourWeeks = "EveryFourWeeks"
}
export interface ISubscriptionScheduleInterval
{
	isSelected: boolean;
	value: SubscriptionInterval;
	name: string;
}
export enum SubscriptionStatus {
	Inactive = "Inactive",
	Active = "Active",
	Paused = "Paused",
	Deleted = "Deleted"
}
export interface IUserSettings
{
	firstName?: string;
	lastName?: string;
	email?: string;
	phoneNumber?: string;
	addressLine1?: string;
	zipCode?: string;
	city?: string;
	subscribeToNewsletter: boolean;
	acceptSms: boolean;
}
export interface IContactInformation
{
	firstName?: string;
	lastName?: string;
	email?: string;
	phoneNumber?: string;
	subscribeToNewsletter: boolean;
	acceptSms: boolean;
}
export interface IValidationError
{
	propertyName: string;
	displayMessage: string;
}
export interface IVariantAvailability
{
	variantId: number;
	lastUpdated?: any;
	online: IDetailedAvailability;
	stores: IStoreDetails[];
}
export interface IDetailedAvailability
{
	inStock: boolean;
	lowInStock: boolean;
}
export interface IStoreDetails
{
	name: string;
	availability: IDetailedAvailability;
	code: string;
}
export interface IAgeGateSettings
{
	title?: string;
	description?: string;
	above18ButtonText?: string;
	below18LinkText?: string;
	below18Title?: string;
	below18Text?: string;
	below18ButtonText?: string;
	below18Url?: string;
}
export interface IAvailabilitySettings
{
	inStockThresholdValueOnline: number;
	inStockThresholdValueInStore: number;
	lowInStockThresholdValueOnline: number;
	lowInStockThresholdValueInStore: number;
}
export interface ICartSettings
{
	standardCartNotices: INotice[];
	subscriptionCartNotices: INotice[];
	conflictingCartNotices: INotice[];
	paymentLogoUrls: string[];
	emtpyStandardCartCta: ISimpleLinkItem;
	emtpySubscriptionCartCta: ISimpleLinkItem;
}
export interface ICheckoutSettings
{
	enableClickAndCollect: boolean;
	enablePromotions: boolean;
}
export interface ICurrencySettings
{
	currencyPrefix?: string;
	currencySuffix?: string;
	currencyCode?: string;
}
export interface IFooterSettings
{
	copyrightText?: string;
	logoUrl?: string;
	footerLinks: ISimpleLinkItem[];
	footerIconUrls: string[];
}
export interface IHeaderSettings
{
	searchPlaceHolder?: string;
	logoUrl?: string;
	mainMenuItems: IHeaderMenuItem[];
	subMenuLinks: ISimpleLinkItem[];
	healthWarnings: string[];
}
export interface IHeaderMenuItem
{
	linkText?: string;
	linkUrl?: string;
	underline: boolean;
	buttonText?: string;
	buttonUrl?: string;
	linkGroups: IHeaderLinkGroup[];
}
export interface IHeaderLinkGroup
{
	heading?: string;
	links: ISimpleLinkItem[];
}
export interface IIconItemModel
{
	text?: string;
	iconUrl?: string;
}
export interface ILoginSettings
{
	createAccountCta: string;
	createAccountConfirmationMessage: string;
	termsAndConditions: string;
}
export interface IMarketSettings
{
	countryCode?: string;
	currency: ICurrencySettings;
	contentLanguage?: string;
	locale?: string;
	ingridSiteId?: string;
	company: ICompanySettings;
	markets: IMarket[];
	url: string;
}
export interface INewsletterSettings
{
	modalTitle: string;
	modalMainBody: string;
	termsAndConditions: string;
	confirmationMessage: string;
	clicksToTrigger: number;
	inactivateNewsletterModal: boolean;
}
export interface IPageSettings
{
	storeListingPage: IPageSettingsInfo;
	myAccountPage: IPageSettingsInfo;
	notFoundPage: IPageSettingsInfo;
	mainSnusListingPage: IPageSettingsInfo;
	mainSnusSubscriptionListingPage: IPageSettingsInfo;
	mainNicotinePouchesListingPage: IPageSettingsInfo;
	mainOtherAssortmentListingPage: IPageSettingsInfo;
	searchResultsPage: IPageSettingsInfo;
	ingridTrackingPage: IPageSettingsInfo;
	favouritesPage: IPageSettingsInfo;
	loginPage: IPageSettingsInfo;
	customerServicePage: IPageSettingsInfo;
}
export interface IPageSettingsInfo
{
	id?: string;
	url?: string;
}
export interface IProductSettings
{
	productPageUsps: IIconItemModel[];
	backInStockPrivacyPolicy?: string;
	preSelectedProductVariantIndex: number;
	nicotinePouchesHealthWarning?: string;
	flagsToHideOnSubscription?: string;
	showQuickInfoOnProductPage: boolean;
	disablePurchaseIfOutOfStock: boolean;
	enableStoreInventory: boolean;
	typeParametricLinkUrl?: string;
	formatParametricLinkUrl?: string;
	formatParametricNicotinePouchesLinkUrl?: string;
	flavorParametricLinkUrl?: string;
	strengthParametricLinkUrl?: string;
	strengthParametricNicotinePouchesLinkUrl?: string;
	brandParametricLinkUrl?: string;
}
export interface IQuickMenuSettings
{
	showQuickMenu: boolean;
	links: ISimpleLinkItem[];
}
export interface ISearchSettings
{
	popularSearchLinks?: ISimpleLinkItem[];
	emptySearchResultCta?: string;
}
export interface ISiteSettings
{
	zendesk: IZendeskSettings;
	pageSettings: IPageSettings;
	headerSettings: IHeaderSettings;
	footerSettings: IFooterSettings;
	quickMenuSettings: IQuickMenuSettings;
	ageGateSettings: IAgeGateSettings;
	siteNotices: INotice[];
	resources: any;
	marketSettings: IMarketSettings;
	productSettings: IProductSettings;
	cartSettings: ICartSettings;
	loginSettings: ILoginSettings;
	newsletterSettings: INewsletterSettings;
	searchSettings: ISearchSettings;
	checkoutSettings: ICheckoutSettings;
}
export interface IZendeskSettings
{
	enableChatWidget: boolean;
}
export interface IProductsResult
{
	products: IProduct[];
	filteredProducts: IProduct[];
	tags: ITag[];
	totalItemCount: number;
	hasNextPage: boolean;
	page: number;
	pageSize: number;
}
