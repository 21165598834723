// The v-epi-edit directive is responsible for adding the
// necessary attributes on-page-edit needs to render its overlay.

import { DirectiveBinding } from 'vue';
import { storeToRefs } from 'pinia';
import { usePageContentStore } from '~/store/pageContent';

function toggleEditAttributes(el: HTMLElement, binding:DirectiveBinding, isEditable = false) {
  //const isEditable = resolvedContent.mode === ContextMode.Edit
  if (isEditable) {
    el.setAttribute('data-epi-property-name', binding.value);
    el.setAttribute('data-epi-property-render', 'none');
  } else {
    el.removeAttribute('data-epi-property-name');
    el.removeAttribute('data-epi-property-render');
  }
}

export default defineNuxtPlugin(nuxtApp => {
  const pageStore = usePageContentStore();
  const { isEpiEdit } = storeToRefs(pageStore);

  nuxtApp.vueApp.directive('epi-edit', {
    beforeMount(el, binding) {
      toggleEditAttributes(el, binding, isEpiEdit.value);
    },
    updated(el, binding) {
      toggleEditAttributes(el, binding, isEpiEdit.value);
    },
  });
});
