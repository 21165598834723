<template>
  <div class="h-screen layout">
    <div class="bg-primary h-48 relative flex items-center justify-center w-full">
      <ChevronLeftIcon
        class="h-20 w-20 object-contain text-white absolute left-24 cursor-pointer"
        @click="onGoBack"
      />
      <NuxtLink to="/" class="w-144 flex-shrink-0">
        <img
          :src="'/logos/swm-logotype-inverted.svg?url'"
          class="w-144 object-contain"
          alt="Swedish Match Logo"
        >
      </NuxtLink>
    </div>
    <div v-if="runtimeConfig.public.environmentName === 'development'">
      <pre>{{ error }}</pre>
    </div>
    <div class="h-full flex flex-col justify-center items-center layout-container">
      <div class="mb-16">
        <img
          :src="'/heroicons/outline/face-frown.svg?url'"
          class="w-48 object-contain"
          alt="Swedish Match Logo"
        >
      </div>
      <div class="text-xsmall-medium text-center mb-8">
        {{ errorPageResources.title }}
      </div>
      <div class="text-xsmall text-center mb-24">
        {{ errorPageResources.subTitle }}
      </div>
      <NuxtLink class="mb-24 btn btn--primary" to="/">
        {{ errorPageResources.button }}
      </NuxtLink>
      <div class="rounded-md bg-grey200 p-16">
        <div class="mb-8 text-xsmall-medium">
          {{ errorPageResources.uspHeadline }}
        </div>
        <div class="flex mb-8 text-xsmall items-center">
          <img :src="'/heroicons/outline/arrow-path.svg?url'" class="mr-8 w-16">
          {{ errorPageResources.usp1 }}
        </div>
        <div class="flex mb-8 text-xsmall items-center">
          <img :src="'/heroicons/outline/clock.svg?url'" class="mr-8 w-16">
          {{ errorPageResources.usp2 }}
        </div>
        <div class="flex text-xsmall items-center">
          <img :src="'/heroicons/outline/envelope.svg?url'" class="mr-8 w-16">
          {{ errorPageResources.usp3 }} <span class="ml-4 underline"><a href="mailto: customerservice@swedishmatch.com">customerservice@swedishmatch.com</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ChevronLeftIcon from '@heroicons/vue/24/outline/esm/ChevronLeftIcon';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContentStore = useGlobalContentStore();
const runtimeConfig = useRuntimeConfig();

const errorPageResources = globalContentStore?.siteSettings?.resources?.errorPageResources || {
  title: 'Well, this is awkward...',
  subTitle: 'Something has gone wrong on our side. We are aware of this and is working on fixing it.',
  button: 'Go to the Start Page',
  uspHeadline: 'Does the page not work? Try to:',
  usp1: 'Refresh the site (sometimes it helps)',
  usp2: 'Try again in 30 minutes',
  usp3: 'Contact',
};

const props = defineProps({
  error: Object,
});

const onGoBack = () => {
  const router = useRouter();
  router.back();
};
</script>
