import { useGlobalContentStore } from '~/store/globalContent';
import { useApplicationInsights } from '~/store/applicationInsights';
import * as Sentry from '@sentry/vue';

const lastError = ref(null) as any;
const lastErrorCode = ref<number | null>(null);
const lastResponse = ref(null) as any;
const pending = ref(false);

export default function useApiFetch() {

  const apiGet = async<T>(url: string, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'GET', null, additionalHeaders);
  };

  const apiPost = async<T>(url: string, body?: null | object, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'POST', body, additionalHeaders);
  };

  const apiPut = async<T>(url: string, body?: null | object, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'PUT', body, additionalHeaders);
  };

  const apiDelete = async<T>(url: string, additionalHeaders? : null | object) : Promise<T> => {
    return req<T>(url, 'DELETE', null, additionalHeaders);
  };

  const logError = async(url: string, opts: any, body: any, statusCode: number, errorMessage? : null | string) : Promise<void> => {

    const appInsights = useApplicationInsights();

    opts.body = JSON.stringify( body, maskInfo );
    const logObj = {
      url: url,
      opts: opts,
      statusCode: statusCode,
      exceptionMessage: (errorMessage?? ''),
    };
    const logMessage = 'ERROR WHEN FETCHING FROM API: ' + JSON.stringify(logObj);
    appInsights.trackTrace(logMessage);
  };

  const maskInfo = (key : string, value : any) => {
    let maskedValue = value;
    if (['password',
      'confirmPassword',
      'phoneNumber',
      'lastName',
      'email',
    ].includes(key)) 
    {
      maskedValue = '*'.repeat(Math.min(value.length,5));
    }
    return maskedValue;
  };

  const req = async<T>(url: string, method: string, body?: null | object, additionalHeaders? : null | object) : Promise<T | any> => {
    pending.value = true;

    lastResponse.value = null;
    lastError.value = null;
    lastErrorCode.value = null;
    const globalContentStore = useGlobalContentStore();
    const runtimeConfig = useRuntimeConfig();

    try {
      const opts = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': globalContentStore.getAcceptLanguage,
          'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
        },
        method: method,
        key: url,
      } as any;
      
      if (process.server) {
        opts.headers['X-Epi-Host'] = useRequestHeaders()['x-forwarded-host'];
        opts.headers['Cookie'] = useRequestHeaders()['cookie'];
      }
      
      if (body) {
        opts.body = JSON.stringify(body);
      }

      if (additionalHeaders) {
        opts.headers = Object.assign(opts.header, additionalHeaders);
      }

      const fullUrl = `${process.client ? runtimeConfig.public.apiUrl : runtimeConfig.apiUrl}${url}`;

      const { data, error } = await useFetch<T>(
        fullUrl,
        opts);

      // Log 400, 403 and 500 exceptions for /cart
      if (error.value?.statusCode && 
        ['cart'].includes(url) && 
        [400,403,500].includes(error.value?.statusCode))
      {
        await logError(url, opts, body?? {}, error.value?.statusCode, error?.value?.data.message);
      }

      if (error?.value?.data) {
        lastError.value = error.value.data;
        lastErrorCode.value = error.value?.statusCode || null;
      } else {
        lastResponse.value = data.value;
        return data.value as T;
      }

    } catch (e) {
      Sentry.captureException(e);
    } finally {
      pending.value = false;
    }
  };

  return {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
    lastError,
    lastErrorCode,
    lastResponse,
    pending,
  };
}
