import { defineStore } from 'pinia';
import { useGlobalContentStore } from './globalContent';
import { useCartStore } from './cart';
import { CartType } from '~/api-types';

export const useVoyadoStore = defineStore('voyado', {
  state: () => ({
    contactId: null as string | null,
  }),
  actions: {
    async updateCart() {
      const globalContentStore = useGlobalContentStore();
      const cartStore = useCartStore();
      const lastUpdatedCart = ref(cartStore.lastUpdatedCart as CartType || CartType.Standard);
      
      if ((window as any).va) {
        const cart = lastUpdatedCart.value === CartType.Subscription ? cartStore.subscriptionCart : cartStore.standardCart;
        
        return (window as any).va('cart', {
          cartRef: cart?.id,
          contactId: this.contactId,
          cartUrl: cart?.abandonedCartUrl,
          locale: globalContentStore.marketSettings.locale,
          items: cart?.items.map(item => ({ itemId: item.partNo, quantity: item.quantity })),
        });
      }
    },
    async setContactIdFromEclubQueryParam(eclub: string): Promise<void> {
      const globalContentStore = useGlobalContentStore();
      const { apiPost } = useApiFetch();

      if ((window as any).va) {
        const contactId = await apiPost<string>(
          `voyado/contactId/eclub?countryCode=${globalContentStore.marketSettings.countryCode}&eclub=${eclub}`
        );
        this.setContactId(contactId);
      }
    },
    setContactId(contactId: string): void {
      if ((window as any).va) {
        (window as any).va('setContactId', contactId);
        this.contactId = contactId;
      }
    },
    sendProductView(category: string, itemId: string): void {
      const globalContentStore = useGlobalContentStore();
      if ((window as any).va) {
        (window as any).va('productview', {
          categoryName: category,
          itemId: itemId,
          contactId: this.contactId,
          locale: globalContentStore.marketSettings.locale,
        });
      }
    },
    emptyCart(cartRef): void {
      if ((window as any).va) {
        (window as any).va('emptyCart', {
          cartRef,
          contactId: this.contactId,
        });
      }
    },
  },
});
