import { defineStore } from 'pinia';
import type { IQuickNavigatorStatus, ILoginStatus, IUserSettings, IICommandResultBase, ICommandResult } from '~/api-types';
import { useGlobalContentStore } from '~/store/globalContent';
import { useVoyadoStore } from '~/store/voyado';
import { useFavouritesStore } from '~/store/favourites';
import * as Sentry from '@sentry/vue';
const { apiGet } = useApiFetch();

export type LoginModalContent = 'login' | 'signup' | 'reminder' | 'signupComplete'

export const useUiStore = defineStore('uiStore', {
  state: () => ({
    showMobileMenu: false,
    showCartMenu: false,
    showNewsletterModal: false,
    isMobile: true,
    isTablet: false,
    showEpiButton: false,
    showLogin: false,
    loginType: 'login' as LoginModalContent,
    signupStatus: '' as string,
    /**
     * @important - super super important
     * isLoggedIn is only used for presentation, ie show buttons in nav
     * it should NEVER be used for security
     */
    isLoggedIn: false,
    isAdmin: false,
    isIdentifiedContact: false,
    isImpersonating: false,
    canImpersonate: false,
    contactId: null as string | null,
    preventLoginNavigate: false,
    hasLoadedCheckLoginStatus: false,
    userNewsletterStatus: false,
    userFirstName: '' as string | undefined,
    userLastName: '' as string | undefined,
    userEmail: '' as string | undefined,
    savedPartNo: '' as string | undefined,
  }),
  actions: {
    setIsMobile(payload: boolean) {
      this.isMobile = payload;
    },
    setIsTablet(payload: boolean) {
      this.isTablet = payload;
    },
    setIsLoggedIn(payload: boolean) {
      this.isLoggedIn = payload;
    },
    setIsAdmin(payload: boolean) {
      this.isAdmin = payload;
    },
    setIsIdentifiedContact(payload: boolean) {
      this.isIdentifiedContact = payload;
    },
    setShowMobileMenu(payload: boolean) {
      this.showMobileMenu = payload;
      this.setMetaThemeColor(payload);
    },
    setShowCartMenu(payload: boolean) {
      this.showCartMenu = payload;
      this.setMetaThemeColor(payload);
    },
    setShowNewsletterModal(payload: boolean) {
      this.showNewsletterModal = payload;
      this.setMetaThemeColor(payload);
    },
    toggleShowMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
      this.setMetaThemeColor(this.showMobileMenu);
    },
    setShowEpiButton(payload: boolean) {
      this.showEpiButton = payload;
    },
    setShowLogin(payload: boolean) {
      this.showLogin = payload;

      if (!payload) this.preventLoginNavigate = false;

      this.setMetaThemeColor(payload);
    },
    setPreventLoginNavigate(payload: boolean) {
      this.preventLoginNavigate = payload;
    },
    setIsImpersonating(payload: boolean) {
      this.isImpersonating = payload;
    },
    setContactId(payload: string) {
      this.contactId = payload;
    },
    setCanImpersonate(payload: boolean) {
      this.canImpersonate = payload;
    },
    setLoginType(payload: LoginModalContent) {
      this.loginType = payload;
    },
    setSignupStatus(payload: string) {
      this.signupStatus = payload;
    },
    setSavedPartNo(payload: string) {
      this.savedPartNo = payload;
    },
    async checkEpiButton() {
      const runtimeConfig = useRuntimeConfig();
      const isEditor: IQuickNavigatorStatus = await $fetch(
        `${runtimeConfig.public.apiUrl}sitesettings/quicknavigator?contentUrl=/`
      );
      if (isEditor.showQuickNavigator) {
        this.setShowEpiButton(isEditor.showQuickNavigator);
      }
    },
    async checkLoggedIn() {
      this.hasLoadedCheckLoginStatus = false;
      const globalContentStore = useGlobalContentStore();
      const voyadoStore = useVoyadoStore();
      const favouritesStore = useFavouritesStore();
      const uiStore = useUiStore();
      const query = 'user/session';
      const result = await apiGet<ILoginStatus>(query);

      await globalContentStore.fetchAntiForgeryToken();

      if (result && result.isLoggedIn && !result.isAdmin) {
        this.setIsLoggedIn(true);
        this.dataLayerPushOnLogin();
        Sentry.setTag('signedIn', true);
      } else {
        this.setIsLoggedIn(false);
        this.hasLoadedCheckLoginStatus = true;
      }

      if (result.isAdmin) {
        this.setIsAdmin(true);
        if (!this.isMobile) {
          uiStore.checkEpiButton();
        }
      } else {
        this.setIsAdmin(false);
      }

      if (result.isIdentifiedContact) {
        this.setIsIdentifiedContact(true);
        Sentry.setTag('contactId', result.contactId);

        if (this.savedPartNo) {
          await favouritesStore.addToFavourites(this.savedPartNo);
        }

        favouritesStore.fetchFavourites();
      } else {
        this.setIsIdentifiedContact(false);
        Sentry.setTag('contactId', null);
      }

      if (result.isImpersonating) {
        this.setIsImpersonating(true);
      } else {
        this.setIsImpersonating(false);
      }

      if (result.canImpersonate) {
        this.setCanImpersonate(true);
      } else {
        this.setCanImpersonate(false);
      }

      if (result.contactId) {
        this.setContactId(result.contactId);
        voyadoStore.setContactId(result.contactId);
      }

      this.savedPartNo = '';
    },
    async fetchUser() {
      const query = 'user/settings';
      const result = await apiGet<IUserSettings>(query);

      this.userNewsletterStatus = result.subscribeToNewsletter;
      this.userFirstName = result.firstName;
      this.userLastName = result.lastName;
      this.userEmail = result.email;

      this.hasLoadedCheckLoginStatus = true;
    },
    async dataLayerPushOnLogin() {
      await this.fetchUser();

      if (!sessionStorage.getItem('pushed-logged-in')) {
        window.dataLayer?.push({
          event: 'Login',
          loggedIn: true,
          newsletterSubscriber: this.userNewsletterStatus,
        });

        sessionStorage.setItem('pushed-logged-in', 'user');
      }
    },
    setMetaThemeColor(payload: boolean) {
      const metaColor = document.querySelector('meta[name="theme-color"]');
      if (metaColor && payload) {
        metaColor.setAttribute('content', '#00374a');
      } else if (metaColor) {
        metaColor.setAttribute('content', '#00455D');
      }
    },
  },
});
